import * as React from "react";
import { Helmet } from "react-helmet";
import { Header } from "../components/header";

const NotFoundFunc = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }} bodyAttributes={{ class: "bg-white text-gray-900 dark:bg-gray-900 dark:text-white" }}>
        <meta charSet="utf-8" />
        <title>Archive Cat</title>
        <meta name="description" content="Archive Cat provides reliable storage for unchanging files." />
      </Helmet>

      <div>
        <Header />
        <main className="relative max-w-7xl mx-auto mt-4">
          <div className="relative w-full lg:w-full lg:pb-1/3">
            <div className="text-center">
              <div className="lg:mt-80">
                <h1 className="text-3xl font-bold">
                  <span className="">Page Not Found</span>
                </h1>
              </div>
            </div>
          </div>
        </main>
        <div className="max-w-7xl mx-auto mt-4 px-4">
          <p className="text-base text-center lg:text-right">&copy; 2020 Archive Cat, LLC. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default NotFoundFunc;
